// React imports

// Remix imports

// Third party imports
import get from 'lodash/get'

// Generated imports

// App imports
import { useCallback } from 'react'
import useUserData from '~/hooks/useUserData'
import getNumberWithCommas from '~/utils/getNumberWithCommas'

const useGetAmountInCurrentCcy = (decimals: number) => {
  const userData = useUserData()
  const rate = get(userData, 'currentCurrency.currentRate', 1)
  const symbol = get(userData, 'currentCurrency.symbol', 'US$')

  const funcCallback = useCallback(
    (amount: any) => {
      if (!rate || !symbol) {
        return `US$\u00A0${getNumberWithCommas(amount, decimals)}`
      }
      return `${symbol}\u00A0${getNumberWithCommas(amount * rate, decimals)}`
    },
    [rate, symbol, decimals]
  )
  return { getAmountInCurrentCcy: funcCallback }
}

export default useGetAmountInCurrentCcy
